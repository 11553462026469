/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-background-color: #F5f7f7;
    --ion-text-color: #000000;
    --ion-text-color-contrast: #ffffff;
    --ion-background-color-rgb: 0,0,0;
    --ion-drop-shadow: rgba(0,0,0,0.35);
    --ion-background-color-contrast: #000000;
    --ion-color-primary: #990000;
    --ion-color-primary-rgb: 153,0,0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #870000;
    --ion-color-primary-tint: #a31a1;
    --ion-color-secondary: #086788;
    --ion-color-secondary-rgb: 8,103,136;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #d8d9d9;
    --ion-color-secondary-tint: #f6f8f8;
    --ion-color-tertiary: #006482;
    --ion-color-tertiary-rgb: 0,100,130;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #005872;
    --ion-color-tertiary-tint: #1a748f;
    --ion-color-success: #1d8900;
    --ion-color-success-rgb: 45,211,111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    --ion-color-danger: #ff0000;
    --ion-color-danger-rgb: 235,68,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,36,40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146,148,156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,245,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
}

.ion-page.test {
    --ion-color-primary: #086788;
    --ion-color-primary-rgb: 8,103,136;
}

body.dark, .ios body.dark {
    --ion-background-color-contrast: #ffffff;
    --ion-background-color: #1e1e1e;
    --ion-background-color-rgb: 18,18,18;
    --ion-button-secondary: rgba(238, 238, 238, 0.15);
    --ion-button-active: rgba(255, 255, 255, 0.85);
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
}