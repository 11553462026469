.splashScreen {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80vw;
    text-align: center;
}
.splashScreen h1 {
    color: white;
}
.splashLogo {
    display: block;
    margin: 0 auto;
}

.ion-page {
    background: var(--ion-color-primary);
}

.splashButton{
    max-width: 250px;
    display: block;
    margin: 0 auto;
}