ion-content#main-content {
    --offset-top: -56px !important;
    
}
ion-content#main-content > .container {
    padding-top: 15px;
}
ion-item {
    --padding-start: 0 !important;
}
ion-item > .item-native {
    padding-inline-start: 0 !important;
}
ion-card{
    margin: 5px 0px 5px 5px;
}
canvas {
    border: solid 1px;
    width: 100%;
    /* This will make the canvas height and width fluid */
    /*object-fit: contain;*/
}
ion-content, ion-router-outlet {
    background: var(--ion-background-color);
}
ion-label {
    font-weight: 600;
}
.form-field {
    padding-bottom: 10px;
}
.alignRight{
    text-align: right;
}
.indent{
    padding-left: 5px;
}

.selectedCard {
    background-color: var(--ion-color-secondary);
    color: var(--ion-text-color-contrast);
}
.selectedCard a {
    color: #FFA500;
}

.floatRight{
    float: right;
}
.required {
    color: var(--ion-color-primary);
}
.scannerToggle ion-icon {
    padding-right: 10px;
}